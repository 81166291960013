<template>
  <v-container>
    <page-title title="商品申請退貨" />
    <block-title :hasOrder="false">{{
      `退貨申請 - Step ${stage}`
    }}</block-title>

    <bar class="mb-5" type="primary">
      <template v-slot:label> 訂單編號 </template>
      <template v-slot:value>{{ readData && readData.order_no }}</template>
    </bar>
    
    <customForm
      :getFormKey.sync="formKey"
      :slotComponents="slotComponents"
      :handlerSubmit="createApi"
      :value="readData"
      :commonProps="{ stage, show: enableBackOrder }"
    >
      <template v-slot:action="{ defaultKey }">
        <StepButton
          :defaultKey="defaultKey"
          :stage.sync="stage"
          :show="enableBackOrder"
          :orderStatus="orderStatus"
          @handleNextStep="handleNextStep"
          @handlePreviousStep="handlePreviousStep"
        ></StepButton>
      </template>
    </customForm>
  </v-container>
</template>

<script lang="babel" type="text/babel">


import customForm from "@/components/form/custom/customForm.vue";
import StepButton from "./StepButton.vue";
import orderConstants from "@/modules/base/config/orderConstants"
import qs from 'qs'

export default {
  components: {
    customForm,
    StepButton
  },
  
  data: () => ({
    auth: {
      type: Boolean,
    },
    readData : null,
    orderStatus : null,
    stage : 1,
    formKey: null,
    photos : null
  }),
  watch: {


  },
  async created () {
    await this.beforeFormInit();

  },
  computed: {
    enableBackOrder() {
      return ['finished', 'sended'].includes(this.orderStatus)
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    providerId() {
      return this.$route.params.providerId
    },
    memberId() {
      return this.$store.getters[`token/memberId`]
    },

    slotComponents() {
      return [
        {
          values : ['items','backReason'],
          component : () => import('@/modules/store/views/orderBack/BackStep.vue')
        },

      ];
    },
  },
  methods: {
    async beforeFormInit() {
      this.$store.dispatch('loading/active')
      try {
        await Promise.all([
          this.readApi()
        ])
      } catch(err) {
        
      } finally {
        this.$store.dispatch('loading/close')
      }

    },
    async readApi() {
      const res =  await this.$api.collection.orderApi.read(this.storeId,this.providerId,this.$route.params.orderId)
      if(!res) {
        this.$snotify.error('', '')
      } else {
        // 退貨數量預設為出貨數量
        res.items = [...res.normal_items, ...res.refrigeration_items, ...res.freezing_items]
        res.items.forEach(item => {
          item.return_count = this.$helper.reverseShippingCount(item.shipping, item.shipping_unit, 0);
        })
        this.readData = res
        this.orderStatus = res.status
      }
      
    },
    async createApi(data) {
      this.$root.gaLogEvent('商店_點擊_退貨')
       this.$store.dispatch('loading/active')
       try {
        const cloneData = this.$eagleLodash.cloneDeep(data)
        for(let key in cloneData) {
          const matched = key.match(/count/)
          if(matched) cloneData[key] = this.$helper.transformShippingCount({count: cloneData[key], force: true})
        }

        let res = await this.$api.collection.orderApi.applyBack(
            this.storeId,
            this.providerId,
            this.$route.params.orderId,
            qs.stringify(cloneData)
        )

        this.readData = res 
   
        if(res.status == 'APPLY')
          this.$snotify.success("申請成功");

          this.$router.push({
            name: "store-order-detail",
            params: { orderId: this.$route.params.orderId ,providerId: this.providerId },
          });
       } catch (error) {
          console.error(error)
          this.$snotify.error('發生意外錯誤')
       } finally  {
        this.$store.dispatch('loading/close')
       }
    },
    // 用來接收子層傳上來的狀態 , 暫無使用
    // setPhotos() {
    //   console.log('set')
    // },
    handleNextStep() {
      this.stage += 1
    },
    handlePreviousStep() {
      this.stage -= 1
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
